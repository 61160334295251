window.addEventListener('DOMContentLoaded', event => {
  // Activate feather
  feather.replace();

  // Enable tooltips globally
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // Enable popovers globally
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl);
  });

  // Toggle the side navigation
  const sidebarToggle = document.body.querySelector('#sidebarToggle');
  if (sidebarToggle) {
      // Uncomment Below to persist sidebar toggle between refreshes
      // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
      //     document.body.classList.toggle('sidenav-toggled');
      // }
      sidebarToggle.addEventListener('click', event => {
          event.preventDefault();
          document.body.classList.toggle('sidenav-toggled');
          localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sidenav-toggled'));
      });
  }

  // Close side navigation when width < LG
  const sidenavContent = document.body.querySelector('#layoutSidenav_content');
  if (sidenavContent) {
      sidenavContent.addEventListener('click', event => {
          const BOOTSTRAP_LG_WIDTH = 992;
          if (window.innerWidth >= 992) {
              return;
          }
          if (document.body.classList.contains("sidenav-toggled")) {
              document.body.classList.toggle("sidenav-toggled");
          }
      });
  }

  // Add active state to sidbar nav links
  let activatedPath = window.location.pathname.match(/([\w-]+\.html)/, '$1');

  if (activatedPath) {
      activatedPath = activatedPath[0];
  } else {
      activatedPath = 'index.html';
  }

  const targetAnchors = document.body.querySelectorAll('[href="' + activatedPath + '"].nav-link');

  targetAnchors.forEach(targetAnchor => {
      let parentNode = targetAnchor.parentNode;
      while (parentNode !== null && parentNode !== document.documentElement) {
          if (parentNode.classList.contains('collapse')) {
              parentNode.classList.add('show');
              const parentNavLink = document.body.querySelector(
                  '[data-bs-target="#' + parentNode.id + '"]'
              );
              parentNavLink.classList.remove('collapsed');
              parentNavLink.classList.add('active');
          }
          parentNode = parentNode.parentNode;
      }
      targetAnchor.classList.add('active');
  });
});


//ここからchat page script
// カテゴリーボタンにイベントリスナーを追加
const categoryButtons = document.querySelectorAll('.category-btn');
if (categoryButtons.length > 0) {
    categoryButtons.forEach(button => {
        button.addEventListener('click', (e) => {
            const categoryId = e.target.getAttribute('data-category-id');
            const url = `/${categoryId}/category_posts`;
            
            // 新しいタブでURLを開く
            window.open(url, '_blank');
        });
    });
}

// const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

// チャットセクション
const chatInput = document.querySelector('.chat-input textarea');
const sendChatBtn = document.querySelector('.chat-input span');
const chatbox = document.querySelector('.chatbox');

if (chatInput && sendChatBtn && chatbox) {
    let userMessage;
    const inputInitHeight = chatInput.scrollHeight;

    // createChatLi関数は既存コードのまま
    const createChatLi = (message, className) => {
        const chatLi = document.createElement('li');
        chatLi.classList.add('chat', className);
        let chatContent = className === "outgoing" ? `<p></p>` : `<span class="material-symbols-outlined">smart_toy</span><p></p>`;
        chatLi.innerHTML = chatContent;
        chatLi.querySelector('p').textContent = message;
        return chatLi;
    }

    // 新しい相談ボタン追加関数 - チャットセクション内に移動
    function addNewChatButton() {
        // チャットボックスのコンテナを取得
        const chatContainer = document.querySelector('.chatbot');
        if (!chatContainer) return;
        
        // ボタンが既に存在する場合は追加しない
        if (document.getElementById('new-chat-button')) return;
        
        // ボタンコンテナを作成
        const buttonContainer = document.createElement('div');
        buttonContainer.style.cssText = 'text-align: right; padding: 5px 10px;';
        
        // 新しいチャットボタンを作成
        const newChatBtn = document.createElement('button');
        newChatBtn.id = 'new-chat-button';
        newChatBtn.textContent = '新しい相談を始める';
        newChatBtn.className = 'btn btn-outline-primary btn-sm';
        newChatBtn.style.marginRight = '5px';
        
        // ボタンのクリックイベント
        newChatBtn.addEventListener('click', function() {
            if (confirm('新しい相談を始めますか？現在の会話は終了します。')) {
                fetch('/start-new-chat', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => response.json())
                .then(data => {
                    // チャットをクリアして初期メッセージを表示
                    chatbox.innerHTML = '';
                    // ここで内部スコープのcreateChatLi関数を使用できる
                    const incomingChatLi = createChatLi('こんにちは 👍 \n本日はどのようなことでお困りですか？\nDFSロボット君があなたのお悩みの相談をおうけ致します。', "incoming");
                    chatbox.appendChild(incomingChatLi);
                })
                .catch(error => {
                    console.error('Error starting new chat:', error);
                });
            }
        });
        
        // ボタンをコンテナに追加
        buttonContainer.appendChild(newChatBtn);
        
        // チャットボックスの適切な位置に挿入
        const categoryButtons = chatContainer.querySelector('.category-buttons');
        if (categoryButtons) {
            chatContainer.insertBefore(buttonContainer, categoryButtons);
        } else {
            chatContainer.insertBefore(buttonContainer, chatContainer.querySelector('.chat-input'));
        }
    }

    const generateResponse = (incomingChatLi) => {
        const messageElement = incomingChatLi.querySelector('p');
        fetch('/get-response', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ message: userMessage })
        })
            .then(response => response.json())
            .then(data => {
                // 改行を維持するように修正
                const message = data.message;
                messageElement.innerHTML = '';
                typeMessage(message, messageElement);
            })
            .catch((error) => {
                messageElement.classList.add('error');
                messageElement.textContent = "一時的なエラーです。少し時間をあけてから、再度お試しください。";
            })
            .finally(() => chatbox.scrollTo(0, chatbox.scrollHeight));
    }

    // タイピングアニメーション関数を改良して改行に対応
    const typeMessage = (message, messageElement) => {
        let formattedMessage = message.replace(/\n/g, "<br>");
        let index = 0;
        let html = '';

        const interval = setInterval(() => {
            if (index < formattedMessage.length) {
                // <br>タグをまとめて処理
                if (formattedMessage.substring(index, index + 4) === "<br>") {
                    html += "<br>";
                    index += 4;
                } else {
                    html += formattedMessage.charAt(index);
                    index++;
                }
                messageElement.innerHTML = html;
            } else {
                clearInterval(interval);
            }
            // タイピング中も自動スクロール
            chatbox.scrollTo(0, chatbox.scrollHeight);
        }, 20); // タイピング速度を少し速く
    };

    const handleChat = () => {
        userMessage = chatInput.value.trim();
        if (!userMessage) return;
        chatInput.value = "";
        chatbox.appendChild(createChatLi(userMessage, "outgoing"));
        chatbox.scrollTop = chatbox.scrollHeight;
        setTimeout(() => {
            const incomingChatLi = createChatLi("考え中...", "incoming")
            chatbox.appendChild(incomingChatLi);
            generateResponse(incomingChatLi);
        }, 600);
    }

    chatInput.addEventListener('input', () => {
        chatInput.style.height = `${inputInitHeight}px`;
        chatInput.style.height = `${chatInput.scrollHeight}px`;
    });

    chatInput.addEventListener('keydown', (e) => {
        if (e.key === "Enter" && !e.shiftKey && window.innerWidth > 800) {
            e.preventDefault();
            handleChat();
        }
    });

    sendChatBtn.addEventListener('click', handleChat);
    
    // ボタンを追加する（既存のコードブロック内）
    // addNewChatButton();
}

//カテゴリーボタンクリック計測用
document.addEventListener('DOMContentLoaded', function() {
    // const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
    const categoryButtons = document.querySelectorAll('.category-buttons .category-btn');

    if (categoryButtons.length > 0) {//csrfTokenMeta &&

        // const csrfToken = csrfTokenMeta.getAttribute('content');

        categoryButtons.forEach(button => {
            button.addEventListener('click', function() {
                const categoryId = this.dataset.categoryId;
                const category = this.dataset.category;
                
                fetch('/api/category-click', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        // 'X-CSRFToken': csrfToken,
                        // 'X-Requested-With': 'XMLHttpRequest'
                    },
                    body: JSON.stringify({ 
                        categoryId,
                        category
                    }),
                })
                .then(response => response.json())
                .then(data => console.log('Category click recorded:', data))
                .catch((error) => console.error('Error:', error));
            });
        });
    } else {
        console.log('Required elements not found. CSRF token or category buttons may be missing.');
    }
});